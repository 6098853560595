import { cn } from '@/lib/utils'
import { NavLinks } from '@/utils/types/common'
import Link from 'next/link'
import { Props } from '.'
import { usePathname } from 'next/navigation'

export const NavItemMobile: React.FC<Props> = ({
  Icon,
  brand = false,
  to,
  name,
  collapsed,
  brandName,
  disabled = false,
}) => {
  const pathname = usePathname()

  return (
    <Link href={NavLinks.ANALYTICS}>
      <div className="w-full flex items-center gap-x-3 hover:bg-gray-100 px-6 py-3">
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center w-[35px] h-8">
            <Icon
              className={cn({
                'dark:text-gray-50': brand,
                'text-[#434344]': !brand,
                'text-gray-900 dark:text-indigo-50': pathname === to && !brand,
              })}
            />
          </div>
        </div>
        <div className="flex justify-center items-center text-gray-700 text-sm">
          {name}
        </div>
      </div>
    </Link>
  )
}
