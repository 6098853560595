'use client'

import { Theme } from '@/features/Nav'
import * as React from 'react'
import { AppProviderProps } from '../ProfileContext/types'

export const ProfileStateContext = React.createContext<
  | {
      theme: Theme
      setTheme: React.Dispatch<React.SetStateAction<Theme>>
      toggleTheme: () => void
    }
  | undefined
>(undefined)

function ThemeProvider({ children }: AppProviderProps) {
  const [theme, setTheme] = React.useState<Theme>(Theme.LIGHT)
  React.useEffect(() => {
    const theme = localStorage.getItem('theme')
    if (theme === Theme.DARK) {
      setTheme(Theme.DARK)
      document.documentElement.classList.add('dark')
    } else {
      setTheme(Theme.LIGHT)
      document.documentElement.classList.remove('dark')
    }
  }, [])
  const toggleTheme = React.useCallback(() => {
    if (theme === Theme.LIGHT) {
      setTheme(Theme.DARK)
      document.documentElement.classList.add('dark')
      localStorage.setItem('theme', Theme.DARK)
    } else {
      setTheme(Theme.LIGHT)
      document.documentElement.classList.remove('dark')
      localStorage.setItem('theme', Theme.LIGHT)
    }
  }, [theme])
  const value = { theme, setTheme, toggleTheme }
  return (
    <ProfileStateContext.Provider value={value}>
      {children}
    </ProfileStateContext.Provider>
  )
}

const useTheme = () => {
  const context = React.useContext(ProfileStateContext)
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }
  return context
}

export { ThemeProvider, useTheme }
