import { Prisma } from '@prisma/client'

const experienceWithSkills = Prisma.validator<Prisma.ExperienceDefaultArgs>()({
  include: {
    skills: true,
  },
})

export type ExperienceWithSkills = Prisma.ExperienceGetPayload<
  typeof experienceWithSkills
>

const roleWithClient = Prisma.validator<Prisma.RoleDefaultArgs>()({
  include: {
    client: true,
  },
})

export type RoleWithClient = Prisma.RoleGetPayload<typeof roleWithClient>

const roleWithClientAndRequirements = Prisma.validator<Prisma.RoleDefaultArgs>()({
  include: {
    client: true,
    requirements: {
      include: {
        qualificationRequirement: {
          include: {
            qualificationType: true,
          },
        },
        roleAttribute: true,
      },
    },
  },
})

export type RoleWithClientAndRequirements = Prisma.RoleGetPayload<
  typeof roleWithClientAndRequirements
>

const clientWithRole = Prisma.validator<Prisma.ClientDefaultArgs>()({
  include: {
    roles: true,
  },
})

export type ClientWithRoles = Prisma.ClientGetPayload<typeof clientWithRole>

const feedbackWithUser = Prisma.validator<Prisma.FeedbackDefaultArgs>()({
  include: {
    user: true,
  },
})

export type FeedbackWithUser = Prisma.FeedbackGetPayload<typeof feedbackWithUser>

const userWithPermission = Prisma.validator<Prisma.UserDefaultArgs>()({
  include: {
    permission: true,
  },
})

export type UserWithPermission = Prisma.UserGetPayload<typeof userWithPermission>

const user = Prisma.validator<Prisma.UserDefaultArgs>()({})

export type User = Prisma.UserGetPayload<typeof user>

const candidateTagWithTag = Prisma.validator<Prisma.CandidateTagDefaultArgs>()({
  include: {
    tag: true,
  },
})

export type CandidateTagWithTag = Prisma.CandidateTagGetPayload<
  typeof candidateTagWithTag
>

const interviewFeedbackWithUser =
  Prisma.validator<Prisma.InterviewFeedbackDefaultArgs>()({
    include: {
      user: true,
    },
  })
export type InterviewFeedbackWithUser = Prisma.InterviewFeedbackGetPayload<
  typeof interviewFeedbackWithUser
>

const candidateDraftNotes = Prisma.validator<Prisma.DraftNotesDefaultArgs>()({
  select: {
    id: true,
    updatedAt: true,
    candidateId: true,
    comment: true,
    type: true,
  },
})

export type CandidateDraftNotes = Prisma.DraftNotesGetPayload<
  typeof candidateDraftNotes
>

const candidateWithRoleFeedbacksUserAndCandidateTags =
  Prisma.validator<Prisma.CandidateDefaultArgs>()({
    include: {
      role: roleWithClient,
      feedbacks: feedbackWithUser,
      user: true,
      candidateTags: candidateTagWithTag,
      interviewFeedbacks: interviewFeedbackWithUser,
      DraftNotes: candidateDraftNotes,
    },
  })

export type CandidateWithRoleFeedbacksUserAndCandidateTags =
  Prisma.CandidateGetPayload<typeof candidateWithRoleFeedbacksUserAndCandidateTags>

const candidateWithProfile = Prisma.validator<Prisma.CandidateDefaultArgs>()({
  include: {
    profile: true,
    candidateTags: candidateTagWithTag,
  },
})

export type CandidateWithProfile = Prisma.CandidateGetPayload<
  typeof candidateWithProfile
>

const feedbackWithCandidateWithProfile =
  Prisma.validator<Prisma.FeedbackDefaultArgs>()({
    include: {
      candidate: candidateWithProfile,
    },
  })

export type FeedbackWithCandidateWithProfile = Prisma.FeedbackGetPayload<
  typeof feedbackWithCandidateWithProfile
>

const allProfileWithCandidatesExperiencesSkills =
  Prisma.validator<Prisma.ProfileDefaultArgs>()({
    select: {
      id: true,
      name: true,
      linkedinHandle: true,
      outreachEmail: true,
      createdAt: true,
      location: true,
      recommendation: true,
      candidates: {
        select: {
          id: true,
          role: {
            select: {
              id: true,
              name: true,
              client: {
                select: {
                  id: true,
                  name: true,
                },
              },
            },
          },
          user: {
            select: {
              name: true,
              picture: true,
              id: true,
            },
          },
          stage: true,
          feedbacks: {
            select: {
              id: true,
              user: {
                select: {
                  name: true,
                  picture: true,
                  id: true,
                },
              },
              quality: true,
              type: true,
              createdAt: true,
            },
          },
          interviewFeedbacks: {
            select: {
              id: true,
              user: {
                select: {
                  name: true,
                  picture: true,
                  id: true,
                },
              },
              decision: true,
            },
          },
          DraftNotes: {
            select: {
              candidateId: true,
              updatedAt: true,
            },
          },
        },
      },
    },
  })

export type allProfileWithCandidatesExperiencesSkills = Prisma.ProfileGetPayload<
  typeof allProfileWithCandidatesExperiencesSkills
>

const profileWithCandidatesExperiencesSkills =
  Prisma.validator<Prisma.ProfileDefaultArgs>()({
    include: {
      candidates: candidateWithRoleFeedbacksUserAndCandidateTags,
      experiences: {
        include: {
          skills: true,
        },
      },
      skills: true,
    },
  })

export type ProfileWithCandidatesExperiencesSkills = Prisma.ProfileGetPayload<
  typeof profileWithCandidatesExperiencesSkills
>

const interviewFeedbackWithCandidateWithProfile =
  Prisma.validator<Prisma.InterviewFeedbackDefaultArgs>()({
    include: {
      candidate: {
        include: {
          profile: true,
        },
      },
    },
  })

export type InterviewFeedbackWithCandidateWithProfile =
  Prisma.InterviewFeedbackGetPayload<
    typeof interviewFeedbackWithCandidateWithProfile
  >

const candidateTag = Prisma.validator<Prisma.CandidateTagDefaultArgs>()({})
export type CandidateTag = Prisma.CandidateTagGetPayload<typeof candidateTag>

const qualificationType = Prisma.validator<Prisma.QualificationTypeDefaultArgs>()({})
export type QualificationType = Prisma.QualificationTypeGetPayload<
  typeof qualificationType
>

const company = Prisma.validator<Prisma.CompanyDefaultArgs>()({})
export type Company = Prisma.CompanyGetPayload<typeof company>

export enum NavLinks {
  HOME = '/',
  PROFILES = '/profiles',
  PROJECTS = '/projects',
  ANALYTICS = '/analytics',
  ROLES = '/roles',
}
