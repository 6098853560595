'use client'

import { getUser, login as loginRequest } from '@/services/api/requests'
import { UserWithPermission } from '@/utils/types/common'
import { CredentialResponse } from '@react-oauth/google'
import * as Sentry from '@sentry/nextjs'
import { usePathname, useRouter } from 'next/navigation'
import * as React from 'react'
import { AppProviderProps } from '../ProfileContext/types'
import { Spinner } from '@/assets/icons'

export const AuthStateContext = React.createContext<
  | {
      register: ({ email }: { email: string }) => void
      login: (credentialResponse: CredentialResponse) => Promise<void>
      logout: () => void
      isLoading: boolean
      user: null | UserWithPermission
      error: any
      checkedUserLoggedIn: () => Promise<void>
    }
  | undefined
>(undefined)

function AuthProvider({ children }: AppProviderProps) {
  const [user, setUser] = React.useState<null | UserWithPermission>(null)
  const [error, setError] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const router = useRouter()
  const pathname = usePathname()
  // Register user
  const register = async ({ email }: { email: string }) => {
    setIsLoading(true)
  }

  // Login user
  const login = async (credentialResponse: CredentialResponse) => {
    setIsLoading(true)
    if (credentialResponse.credential) {
      const res = await loginRequest({
        credential: credentialResponse.credential,
      })
      setUser(res)
      setError(false)
      setIsLoading(false)
    } else {
      setIsLoading(false)

      throw Error('Login Failed! Please try again')
    }
  }

  // Logout user
  const logout = () => {}

  const checkedUserLoggedIn = React.useCallback(async () => {
    try {
      if (pathname === '/auth/login') {
        setIsLoading(false)
        return
      }
      setIsLoading(true)
      const user = await getUser()
      if (user) {
        setUser(user)
        Sentry.setUser({
          id: user.id,
          email: user.email,
        })
        setError(false)
      }
      setIsLoading(false)
    } catch (err: any) {
      setError(true)
      Sentry.setUser(null)
      router.push('/auth/login')
    }
  }, [pathname, router])

  React.useEffect(() => {
    if (!user) checkedUserLoggedIn()
  }, [checkedUserLoggedIn, user])
  React.useEffect(() => {
    if (!user && !isLoading && !error && pathname !== '/auth/login') {
      router.push('/auth/login')
    }
  }, [isLoading, pathname, router, user, error])
  return (
    <AuthStateContext.Provider
      value={{
        register,
        login,
        logout,
        checkedUserLoggedIn,
        isLoading,
        user,
        error,
      }}
    >
      {isLoading && error && (
        <div>
          <div
            className="flex justify-center items-center h-full"
            data-testid="svg-spinner"
          >
            <Spinner className="text-gray-900 dark:text-indigo-50 w-4 h-4" />
          </div>
        </div>
      )}
      {!isLoading && children}
    </AuthStateContext.Provider>
  )
}

export { AuthProvider }
