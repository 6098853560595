'use client'

import Link from 'next/link'
import clsx from 'clsx'
import { usePathname } from 'next/navigation'

export type Props = {
  Icon: any
  brand?: boolean
  to: string
  name?: string
  collapsed: boolean
  brandName?: any
  disabled?: boolean
}

export const NavItem: React.FC<Props> = ({
  Icon,
  brand = false,
  to,
  name,
  collapsed,
  brandName,
  disabled = false,
}) => {
  const pathname = usePathname()
  return (
    <Link href={disabled ? (pathname as any) : to}>
      <div
        className={clsx('flex rounded-lg items-center', {
          'justify-center p-6': collapsed,
          'p-2': !collapsed,
          'py-5 px-4': !collapsed && brand,
          '': !brand,
          'bg-stone-50 dark:bg-[#202020]': pathname === to && !brand,
        })}
      >
        <div
          className={clsx('flex justify-center items-center flex-shrink-0', {
            'w-[35px] h-[35px]': brand,
            'w-5 h-5': !brand,
          })}
        >
          <Icon
            className={clsx({
              'dark:text-gray-50': brand,
              'text-[#434344]': !brand,
              'text-gray-900 dark:text-indigo-50': pathname === to && !brand,
            })}
          />
        </div>
        {!collapsed && (
          <>
            {name && (
              <div
                className={clsx('pl-3 text-sm font-medium leading-tight', {
                  'text-gray-900 dark:text-indigo-50': pathname === to && !brand,
                  'text-gray-400': disabled,
                })}
              >
                {name}
              </div>
            )}
            {brandName && <div className="pl-4">{brandName}</div>}
          </>
        )}
      </div>
    </Link>
  )
}
