'use client'
import * as React from 'react'
import { AuthStateContext } from '.'

export const useAuth = () => {
  const context = React.useContext(AuthStateContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}
