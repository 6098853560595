'use client'

import {
  AnalyticsLogo,
  Logo,
  ProfilesLogo,
  Tapioca,
  BriefcaseOutlineIcon,
} from '@/assets/icons'
import { NavItem } from '@/components/navItem'
import { NavItemMobile } from '@/components/navItem/navItemMobile'
import { useAuth } from '@/services/store/AuthContext/hook'
import { useTheme } from '@/services/store/ThemeContext'
import { NavLinks } from '@/utils/types/common'
import { Popover, Transition } from '@headlessui/react'
import { Bars3Icon, MoonIcon, SunIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { Fragment, useState } from 'react'

export enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
}

export const Nav: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(true)
  const { user } = useAuth()
  const { theme, toggleTheme } = useTheme()

  const isAdmin = user?.permission?.name === 'ADMIN' || false
  return (
    <>
      <Popover className="w-full sm:hidden mb-3 bg-white dark:bg-[#191919] rounded-xl border border-gray-200 dark:border-none">
        {({ open }) => (
          <>
            <Popover.Button
              className={clsx(
                'w-full py-3 px-6 border-gray-200 dark:border-gray-800 flex items-center justify-between bg-white dark:bg-[#191919] focus-visible:outline-none',
                {
                  'border rounded-xl': !open,
                  'border-t border-l border-r rounded-t-xl': open,
                }
              )}
            >
              <div className="flex justify-center items-center">
                <div className="flex justify-center items-center w-[35px] h-8">
                  <Logo
                    width={35}
                    height={32}
                    color={theme === Theme.DARK ? '#F9FAFB' : '#050719'}
                  />
                </div>
              </div>
              <div className="flex justify-center items-center">
                <div className="flex justify-center items-center w-6 h-6">
                  <Bars3Icon className="h-6 w-6 text-gray-500" />
                </div>
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-50"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className=" bg-white dark:bg-[#191919] rounded-b-xl border-b border-l border-r border-gray-200 dark:border-gray-800">
                <NavItemMobile
                  Icon={ProfilesLogo}
                  to={NavLinks.PROFILES}
                  name="Profiles"
                  collapsed={false}
                />
                <NavItemMobile
                  Icon={AnalyticsLogo}
                  to={NavLinks.ANALYTICS}
                  name="Analytics"
                  collapsed={collapsed}
                />
                <NavItemMobile
                  Icon={BriefcaseOutlineIcon}
                  to={NavLinks.ROLES}
                  name="Roles"
                  collapsed={collapsed}
                />
                <div
                  className="flex items-center px-6 py-3 cursor-pointer"
                  onClick={toggleTheme}
                >
                  {theme === 'light' && (
                    <SunIcon className="h-6 w-6 text-gray-500" />
                  )}
                  {theme === 'dark' && (
                    <MoonIcon className="h-6 w-6 text-gray-500" />
                  )}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      <div
        className={clsx(
          'hidden sm:block bg-white dark:bg-[#191919] rounded-xl border border-gray-200 dark:border-none',
          {
            grid: true,
            'grid-cols-sidebar min-w-[256px]': !collapsed,
            'grid-cols-sidebar-collapsed': collapsed,
            'transition-[grid-template-columns] duration-300 ease-in-out': true,
          }
        )}
      >
        <div className="flex flex-col justify-between h-full">
          <div onClick={(e) => e.stopPropagation()}>
            <NavItem
              Icon={Logo}
              brand
              to={NavLinks.HOME}
              collapsed={collapsed}
              name="Tapioca"
              brandName={Tapioca}
            />
            <div className="flex flex-col gap-y-1">
              <div
                className={clsx({ 'pt-11 px-2 flex flex-col gap-y-1': !collapsed })}
              >
                <div
                  className={clsx({ 'px-3': collapsed })}
                  onClick={(e) => e.stopPropagation()}
                >
                  <NavItem
                    Icon={ProfilesLogo}
                    to={NavLinks.PROFILES}
                    name="Profiles"
                    collapsed={collapsed}
                  />
                </div>
                <div
                  className={clsx({ 'px-3': collapsed })}
                  onClick={(e) => e.stopPropagation()}
                >
                  <NavItem
                    Icon={AnalyticsLogo}
                    to={NavLinks.ANALYTICS}
                    name="Analytics"
                    collapsed={collapsed}
                  />
                </div>
                <div
                  className={clsx({ 'px-3': collapsed })}
                  onClick={(e) => e.stopPropagation()}
                >
                  <NavItem
                    Icon={BriefcaseOutlineIcon}
                    to={NavLinks.ROLES}
                    name="Roles"
                    collapsed={collapsed}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              className="flex justify-center items-center p-4 cursor-pointer"
              onClick={toggleTheme}
            >
              {theme === 'light' && <SunIcon className="h-6 w-6 text-gray-500" />}
              {theme === 'dark' && <MoonIcon className="h-6 w-6 text-gray-500" />}
            </div>
            <div className="flex flex-shrink-0 p-4 ">
              <a href="#" className="group block w-full flex-shrink-0">
                <div
                  className={clsx('flex items-center', {
                    'justify-center': collapsed,
                  })}
                >
                  <div>
                    <Image
                      className="inline-block h-9 w-9 rounded-full"
                      src={
                        user?.picture ||
                        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                      }
                      alt=""
                      width={36}
                      height={36}
                    />
                  </div>
                  <Transition
                    show={!collapsed}
                    enter="transition ease-out duration-100 transform"
                    enterFrom="opacity-0 w-0"
                    enterTo="opacity-100 w-full"
                    leave="transition ease-in duration-75 transform"
                    leaveFrom="opacity-100 w-full"
                    leaveTo="opacity-0 w-0"
                  >
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900 dark:text-gray-50">
                        {user?.name}
                      </p>
                      <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                        View profile
                      </p>
                    </div>
                  </Transition>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
